<template>
  <div class="alls">
    <PCHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
    <div class="context">
      <div class="ctitle">
        {{ $t('message.news.blucap_unveils') }}
      </div>
      <div class="ctxt-1 color-07">
        {{ $t('message.news.the_blucap_team') }}
      </div>
      <div class="w-1 flex row-between row-center">
        <img src="../../assets/20230905/LinkSimple.svg" class="simg" :class="mindex == 0 ? 'simg_active' : ''"
          @click="copyClip" @mouseenter="handleMouseEnter(0)" @mouseleave="handleMouseLeave(0)">
        <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fblucapsport.com%2Fnews" target="_blank"> <img
            src="../../assets/20230905/FacebookLogo.svg" class="simg" :class="mindex == 1 ? 'simg_active' : ''"
            @mouseenter="handleMouseEnter(1)" @mouseleave="handleMouseLeave(1)"></a>
        <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fblucapsport.com%2Fnews&amp;text=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride."
          target="_blank"><img src="../../assets/20230905/TwitterLogo.svg" class="simg"
            :class="mindex == 2 ? 'simg_active' : ''" @mouseenter="handleMouseEnter(2)"
            @mouseleave="handleMouseLeave(2)"></a>
        <a href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fblucapsport.com%2Fnews" target="_blank"> <img
            src="../../assets/20230905/LinkedinLogo.svg" class="simg" :class="mindex == 3 ? 'simg_active' : ''"
            @mouseenter="handleMouseEnter(3)" @mouseleave="handleMouseLeave(3)"></a>
        <a href="mailto:?subject=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride.&amp;body=https%3A%2F%2Fblucapsport.com%2Fnews"
          target="_blank" rel="nofollow"><img src="../../assets/20230905/mail.svg"
            :class="mindex == 4 ? 'simg_active' : ''" class="simg" @mouseenter="handleMouseEnter(4)"
            @mouseleave="handleMouseLeave(4)"></a>


      </div>

      <div class="ctxt-2 color-07">
        {{ $t('message.news.september_13') }}
      </div>

      <div class="w-2" v-show="this.$i18n.locale!='zh'">

        <iframe  id="player" type="text/html" class="w-2-video"
          :src="this.$i18n.locale!='zh'? 'https://www.youtube.com/embed/hZXzbKbc53c?si=BLKDzATD9mFpSCqY':''" frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
          
      </div>
      <div class="w-2" v-show="this.$i18n.locale=='zh'">
        <iframe class="w-2-video"
        :src="this.$i18n.locale=='zh'?'//player.bilibili.com/player.html?aid=788942498&bvid=BV1FC4y1f7eW&cid=1280556712&p=1':''" scrolling="no"
                frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
      </div>
      <div class="ctxt-3">
        {{ $t('message.news.motorcycle') }}

      </div>
      <p class="ctxt-2 c-m-t color-07">
        {{ $t('message.news.blucap_moto_is') }}

      </p>
      <p class="ctxt-2 c-m-t color-07">
        {{ $t('message.news.in_addition') }}
      </p>
      <p class="ctxt-2 c-m-t color-07">
        {{ $t('message.news.with_blucap_moto') }}
      </p>
      <div>
        <img v-lazy="require('../../assets/20230905/new01.png')" class="nimg01">
      </div>
      <div style="height: 50px;">
        <div class="flex row-between row-center c-m-t">
          <div class="ctxt-4">{{ $t('message.news.navigation_display') }}</div>
          <a href="zip/new01.png" download class="downImg"></a>

        </div>
      </div>

      <p class="ctxt-2 m-t-40 color-07">
        {{ $t('message.news.blucap_has_developed') }}
      </p>


      <div>
        <img v-lazy="require(`../../assets/20230905/${this.$i18n.locale}/new02.png`)" class="nimg01">
      </div>
      <div class="flex row-between row-center c-m-t">
        <div class="ctxt-4">{{ $t('message.news.app_functionality') }}</div>
        <a :href="`zip/${this.$i18n.locale}/new02.png`" download class="downImg"></a>


      </div>
      <p class="ctxt-2 m-t-40 color-07">
        {{ $t('message.news.blucap_has_designed') }}

      </p>
      <p class="ctxt-2 c-m-t color-07">
        {{ $t('message.news.basic_features') }}
      </p>

      <div>
        <img v-lazy="require(`../../assets/20230905/${this.$i18n.locale}/new03.png`)" class="nimg01">
      </div>
      <div class="flex row-between row-center c-m-t">
        <div class="ctxt-4">{{ $t('message.news.app_advanced') }}</div>
        <a :href="`zip/${this.$i18n.locale}/new03.png`" download class="downImg"></a>
      </div>
      <p class="ctxt-3 m-t-40">{{ $t('message.news.design') }}</p>
      <p class="ctxt-2 c-m-t color-07">
        {{ $t('message.news.according_to_blucap') }}
      </p>

      <div class="m-t-40">
        <PCNewSwipper />
      </div>

      <div class="m-t-40">
        <PCNewSwipper01 />
      </div>
      <p class="ctxt-2 m-t-40 color-07">
        {{ $t('message.news.the_blucap_design') }}

      </p>
      <p class="ctxt-2 c-m-t color-07">
        {{ $t('message.news.when_unfolding') }}
      </p>

      <div class="m-t-40">
        <video playsInline loop autoPlay muted class="video_1">
          <source src="https://blk001.oss-us-west-1.aliyuncs.com/OUT3.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="flex row-between row-center c-m-t">
        <div class="ctxt-4">{{ $t('message.news.power_on') }}</div>
        <a href="zip/CG_AutoConnection_Video.mp4" download class="downImg"></a>
      </div>
      <p class="ctxt-2 m-t-40 color-07">
        {{ $t('message.news.the_charging') }}

      </p>


      <div class="m-t-40">
        <video playsInline loop autoPlay muted class="video_1">
          <source src="https://blk001.oss-us-west-1.aliyuncs.com/CG_QuickShift_Video.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="flex row-between row-center c-m-t">
        <div class="ctxt-4">{{ $t('message.news.release') }}</div>
        <a href="zip/CG_QuickShift_Video.mp4" download class="downImg"></a>
      </div>


      <div class="m-t-40">
        <PCNewSwipper02 />
      </div>
      <p class="ctxt-3 m-t-40">{{ $t('message.news.customized') }}</p>
      <p class="ctxt-2 c-m-t color-07">
        {{ $t('message.news.traditional_motorcycle') }}
      </p>

      <div>
        <img v-lazy="require('../../assets/20230905/new06.png')" class="nimg01">
      </div>
      <div class="flex row-between row-center c-m-t">
        <div class="ctxt-4">{{ $t('message.news.prescription_lenses') }}</div>
        <a href="zip/new06.png" download class="downImg"></a>
      </div>
      <p class="ctxt-3 m-t-40">{{ $t('message.news.cutting_edge') }}
      </p>
      <p class="ctxt-2 c-m-t color-07">
        {{ $t('message.news.focused') }}
      </p>

      <div>
        <img v-lazy="require('../../assets/20230905/new05.png')" class="nimg01">
      </div>
      <div class="flex row-between row-center c-m-t">
        <div class="ctxt-4">{{ $t('message.news.microled') }}</div>
        <a href="zip/new05.png" download class="downImg"></a>
      </div>
      <p class="ctxt-2 m-t-40 color-07">{{ $t('message.news.uniquely') }}</p>




      <div class="m-t-40">
        <PCNewSwipper03 />
      </div>

      <div class="m-t-40">
        <PCNewSwipper04 />
      </div>

      <p class="ctxt-3 m-t-40">{{ $t('message.news.sales_plan') }}
      </p>
      <p class="ctxt-2 c-m-t color-07">{{ $t('message.news.official') }}
      </p>


      <p class="ctxt-3 m-t-40">{{ $t('message.news.market_resonance') }}
      </p>
      <p class="ctxt-2 c-m-t color-07">
        {{ $t('message.news.fully') }}
      </p>

      <p class="ctxt-3 m-t-40">{{ $t('message.news.product_key') }}</p>
      <ul>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.real_time') }}
          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.other_hud') }}
          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.glasses_appearance') }}
          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.external_lens') }}
          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.replacement_lenses') }}

          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.inner_lens') }}
          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.voice_control') }}
          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.nose_pad') }}
          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.comes_with_a') }}
          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.light_engine') }}
          </p>
        </li>
        <li>
          <p class="ctxt-4 color-07">
            {{ $t('message.news.remote_control') }}
          </p>
        </li>
      </ul>

      <p class="ctxt-3 m-t-40">{{ $t('message.news.about_blucap') }}</p>
      <p class="ctxt-5" @click="goAbout">{{ $t('message.news.about_us') }}</p>

      <div class="flex row-between row-center m-t-40">
        <div class="w-10">
          <div class="w-10-title">{{ $t('message.news.this_article') }}</div>
          <a href="zip/txt.txt" class="flex row-between row-center" download>
            <div class="w-10-btns flex row-start">
              <img src="../../assets/20230905/d1.svg" class="doimg">
              <div class="w-10-btn">{{ $t('message.news.copy_text') }}</div>
            </div>

            <div></div>
          </a>
        </div>
        <div class="w-10">
          <div class="w-10-title">{{ $t('message.news.download_all') }}</div>
          <a class="flex row-between row-center" :href="`zip/${this.$i18n.locale}/Photos.zip`" download>
            <div class="w-10-btns flex row-start">
              <img src="../../assets/20230905/d2.svg" class="doimg">
              <div class="w-10-btn">{{ $t('message.news.zip569') }}</div>
            </div>
            <div></div>
          </a>

        </div>
      </div>

      <div class="flex row-between row-center m-t-12">
        <div class="w-10">
          <div class="w-10-title">{{ $t('message.news.advertisement') }}</div>
          <a href="zip/Advertisement_Video_EN.mp4" class="flex row-between row-center" download>
            <div class="w-10-btns flex row-start">
              <img src="../../assets/20230905/d1.svg" class="doimg">
              <div class="w-10-btn">{{ $t('message.news.mp4106') }}</div>
            </div>
            <div></div>

          </a>
        </div>
        <div class="w-10">
          <div class="w-10-title">{{ $t('message.news.vertical_advertisement') }}</div>
          <a class="flex row-between row-center" href="zip/30s_Vertical_Advertisement_Video_EN.mp4" download>
            <div class="w-10-btns flex row-start">
              <img src="../../assets/20230905/d2.svg" class="doimg">
              <div class="w-10-btn">{{ $t('message.news.mp4378') }}</div>
            </div>
            <div></div>

          </a>

        </div>
      </div>


      <div class="flex row-between row-center m-t-12">
        <div class="w-10">
          <div class="w-10-title">{{ $t('message.news.cg') }}</div>
          <a href="zip/CG_QuickShift_Video.mp4" class="flex row-between row-center" download>
            <div class="w-10-btns flex row-start">
              <img src="../../assets/20230905/d1.svg" class="doimg">
              <div class="w-10-btn">{{ $t('message.news.mp447') }}</div>
            </div>
            <div></div>

          </a>
        </div>
        <div class="w-10">
          <div class="w-10-title">{{ $t('message.news.cg_auto') }}</div>
          <a class="flex row-between row-center" href="zip/CG_AutoConnection_Video.mp4" download>
            <div class="w-10-btns flex row-start">
              <img src="../../assets/20230905/d2.svg" class="doimg">
              <div class="w-10-btn">{{ $t('message.news.mp445') }}</div>
            </div>
            <div></div>

          </a>

        </div>
      </div>

      <div class="ling"></div>
      <div class="mid">
        <div class="ctxt-3 m-t-40">{{ $t('message.news.for_more') }}</div>
        <div class="flex row-between row-center">
          <div class="w-333">
            <div class="tit01">{{ $t('message.news.zhaot') }}</div>
            <div class="tit02 m-t-8">{{ $t('message.news.brand_marketing') }}</div>
            <div class="tit03">{{ $t('message.news.ztt') }}</div>
          </div>
          <div class="w-333">
            <div class="tit01">{{ $t('message.news.b_m') }}</div>
            <div class="tit03">{{ $t('message.news.mb') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <PCFooter />
    </div>
  </div>
</template>
<script>
import clipboard from 'clipboard';
// import $ from "jquery";
import PCHeader1 from "@/components/header/PCHeader04.vue"; //头部
import PCFooter from "@/components/footer/PCFoote01.vue"; //底部
import PCNewSwipper from "@/components/news/PCNewSwipper.vue"; //底部
import PCNewSwipper01 from "@/components/news/PCNewSwipper01.vue"; //底部
import PCNewSwipper02 from "@/components/news/PCNewSwipper02.vue"; //底部
import PCNewSwipper03 from "@/components/news/PCNewSwipper03.vue"; //底部
import PCNewSwipper04 from "@/components/news/PCNewSwipper04.vue"; //底部
export default {
  components: {
    PCHeader1,
    PCFooter,
    PCNewSwipper,
    PCNewSwipper01,
    PCNewSwipper02,
    PCNewSwipper03,
    PCNewSwipper04
  },
  data() {
    return {
      changeClass1: 1,
      tabIndexs1: 3, //用来传值给子组件,来显示点击了第几个
      mindex: 100,
    };
  },
 
  created() {
    //   这个用来在不同分辨率等比缩放css样式
    // $(function () {
    //   var w = window.screen.width;
    //   var zoom = w / 1920;
    //   $("#app").css({
    //     zoom: zoom,
    //     "-moz-transform": "scale(" + zoom + ")",
    //     "-moz-transform-origin": "top left",
    //   });
    // });
  },
  mounted() {
    //监听页面滚动事件
    // 要防抖
    window.addEventListener("scroll", this.debounce(this.handleScroll, 10));
  },
  methods: {
    // 滑动
    handleScroll: function () {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (top < 60) {
        this.changeClass1 = 1;
      } else {
        this.changeClass1 = 2;
      }
    },
    // 防抖函数
    debounce(fn, wait) {
      let timeout = null;
      return function () {
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
      };
    },
    loc(values) {
      if (values == 1) {
        window.open('https://www.instagram.com/blucap_global/', '_blank')
      } else if (values == 2) {
        window.open('https://www.facebook.com/blucapsport/', '_blank')
      } else if (values == 3) {
        window.open('https://www.youtube.com/@Blucap', '_blank')
      } else if (values == 4) {
        window.open('https://twitter.com/Blucap_global', '_blank')
      }

    },
    downfile(src, name) {
      //创建a标签
      var bqa = document.createElement("a");
      //给a标签的href属性赋值
      bqa.setAttribute("href",);
      //给a标签的download属性赋值,表示下载的文件名
      bqa.setAttribute("download", name + ".png");
      //调用a标签的点击事件
      bqa.click();
      //移除a标签
      bqa.remove()
    },
    goAbout() {
      this.$router.push({ path: '/about' });
    },
    //复制
    copyClip() {
      let text = 'https://blucapsport.com/news';
      clipboard.copy(text);
      alert('已复制到剪贴板！');
    },
    handleMouseEnter(sindex) {
      this.mindex = sindex;
    },
    handleMouseLeave(sindex) {
      console.log('sindex', sindex);
      this.mindex = 100;
    }
  },

};
</script>
<style lang="less" scoped>
.simg {
  cursor: pointer;
  display: block;
  opacity: 0.7;
}

.simg_active {
  opacity: 1;
}

.downImg {
  width: 24px;
  height: 24px;
  background-image: url('../../assets/20230905/Download.svg');
  background-size: cover;
  cursor: pointer;
}

.bottom {
  border-top: 1px solid #303030;
  margin-top: 120px;
}

.context {
  width: 820px;
  margin: 0 auto;
  font-family: League Spartan;
  font-style: normal;
  color: #fff;

  .ctitle {
    font-size: 42px;
    font-weight: 500;
    line-height: 120%;
    margin-top: 120px;
    text-align: center;
  }

  .ctxt-1 {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    text-align: center;

  }

  .color-07 {
    color: rgba(255, 255, 255, 0.70);
  }

  .w-1 {
    width: 231px;
    margin: 0 auto;
    margin-top: 24px;
  }

  .ctxt-2 {
    font-size: 21px;
    font-weight: 400;
    line-height: 120%;
    text-align: left;
    margin-top: 100px;
  }

  .w-2 {
    width: 100%;
    margin-top: 40px;

    .w-2-video {
      width: 100%;
      height: 464px;
    }
  }

  .ctxt-3 {
    font-size: 27px;
    font-weight: 500;
    line-height: 120%;
    margin-top: 40px;
  }

  .c-m-t {
    margin-top: 12px;
  }

  .ctxt-4 {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
  }

  .dimg {
    width: 24px;
  }

  .nimg01 {
    width: 100%;
    margin-top: 40px;
  }

  .m-t-40 {
    margin-top: 40px;
  }

  .m-t-12 {
    margin-top: 12px;
  }

  .video_1 {
    width: 100%;
    display: block;
  }

  ul {
    list-style-type: disc;
    padding-left: 32px;
  }

  ul li::marker {
    color: rgba(255, 255, 255, 0.70);
    font-size: 21px;
  }

  .ctxt-5 {
    font-size: 21px;
    font-weight: 400;
    line-height: 120%;
    color: #249EFF;
    margin-top: 12px;
  }

  .w-10 {
    width: 404px;
    border: 1px solid rgba(255, 255, 255, 0.20);
    background: #1B1B1B;
    height: 120px;
    // padding-left: 24px;
    // padding-top: 24px;
    padding: 24px;

    .w-10-title {
      font-size: 21px;
      font-weight: 400;
      line-height: 120%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .w-10-btns {
      border: 1.5px solid rgba(255, 255, 255, 0.70);
      backdrop-filter: blur(15px);
      // padding: 8px 12px;
      padding-top: 8px;
      padding-bottom: 8px;
      margin-top: 12px;

      .doimg {
        margin-left: 12px;
      }

      .w-10-btn {
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
        color: #fff;
        margin-left: 10px;
        padding-right: 12px;
      }
    }

    .ww-115 {
      width: 115px;
    }

    .ww-156 {
      width: 156px;
    }

    .ww-133 {
      width: 133px;
    }

    .ww-131 {
      width: 131px;
    }

    .ww-140 {
      width: 140px;
    }

    .ww {
      width: 144px;
    }
  }

  .ling {
    width: 100%;
    height: 1px;
    background-color: #303030;
    margin-top: 40px;
  }
}

.w-333 {
  width: 333px;

  .tit01 {
    font-size: 21px;
    font-weight: 600;
    line-height: 120%;
    margin-top: 24px;
  }

  .tit02 {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
  }

  .tit03 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: #249EFF;
    margin-top: 8px;
  }


}
</style>