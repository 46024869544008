<template>
  <div class="all">
    <MPHeader1 :tab-index1="tabIndexs1" :changeClass1="changeClass1" />
    <div class="content">
      <div class="title01">{{ $t('message.news.blucap_unveils') }}</div>
      <div class="txt-16 m-t-12 color-07"> {{ $t('message.news.the_blucap_team') }}</div>
      <div class="w-1 flex row-between row-center">
        <img src="../../assets/20230905/LinkSimple.svg" class="simg" @click="copyClip" @mouseenter="handleMouseEnter(0)"
          @mouseleave="handleMouseLeave(0)">
        <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fblucapsport.com%2Fnews" target="_blank"> <img
            src="../../assets/20230905/FacebookLogo.svg" class="simg" :class="mindex == 1 ? 'simg_active' : ''"
            @mouseenter="handleMouseEnter(1)" @mouseleave="handleMouseLeave(1)"></a>
        <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fblucapsport.com%2Fnews&amp;text=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride."
          target="_blank"><img src="../../assets/20230905/TwitterLogo.svg" class="simg"
            :class="mindex == 2 ? 'simg_active' : ''" @mouseenter="handleMouseEnter(2)" @mouseleave="handleMouseLeave(2)"></a>
        <a href="https://www.linkedin.com/shareArticle?url=https%3A%2F%2Fblucapsport.com%2Fnews" target="_blank"> <img
            src="../../assets/20230905/LinkedinLogo.svg" class="simg" :class="mindex == 3 ? 'simg_active' : ''"
            @mouseenter="handleMouseEnter(3)" @mouseleave="handleMouseLeave(3)"></a>
        <a href="mailto:?subject=Blucap%20Moto%20-%20World's%20first%20motorcycle%20navigation%20sunglasses.Immersing%20yourself%20in%20every%20moment%20of%20the%20ride.&amp;body=https%3A%2F%2Fblucapsport.com%2Fnews"
          target="_blank" rel="nofollow"><img src="../../assets/20230905/mail.svg" class="simg"
            :class="mindex == 4 ? 'simg_active' : ''" @mouseenter="handleMouseEnter(4)" @mouseleave="handleMouseLeave(4)"></a>
        <!-- <img src="../../assets/20230905/FacebookLogo.svg" class="simg" @click="loc(2)">
        <img src="../../assets/20230905/TwitterLogo.svg" class="simg" @click="loc(4)">
        <img src="../../assets/20230905/InstagramLogo.svg" class="simg" @click="loc(1)">
        <img src="../../assets/20230905/YoutubeLogo.svg" class="simg" @click="loc(3)"> -->
      </div>
      <div class="txt-21 m-t-34 color-07">{{ $t('message.news.september_13') }}</div>

      <div class="w-2" v-show="this.$i18n.locale!='zh'">

        <iframe id="player" type="text/html" class="w-2-video"
          :src="this.$i18n.locale!='zh'? 'https://www.youtube.com/embed/hZXzbKbc53c?si=BLKDzATD9mFpSCqY':''" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen></iframe>
      </div>
      <div class="w-2" v-show="this.$i18n.locale=='zh'">
        <iframe class="w-2-video"
                :src="this.$i18n.locale=='zh'?'//player.bilibili.com/player.html?aid=788942498&bvid=BV1FC4y1f7eW&cid=1280556712&p=1':''" scrolling="no"
                frameborder="no" framespacing="0" allowfullscreen="true"> </iframe>
      </div>

      <div class="txt-27 m-t-24">{{ $t('message.news.motorcycle') }}</div>



      <p class="txt-21 color-07">
        {{ $t('message.news.blucap_moto_is') }}
      </p>
      <p class="txt-21 m-t-12 color-07">{{ $t('message.news.in_addition') }}</p>
      <p class="txt-21 m-t-12 color-07">{{ $t('message.news.with_blucap_moto') }}</p>


      <div class="m-t-24">
        <img src="../../assets/20230905/new01.png" class="nimg01">
      </div>
      <div class="flex row-between row-center m-t-12">
        <div class="txt-16">{{ $t('message.news.navigation_display') }}</div>
        <a href="zip/new01.png" download>
          <img src="../../assets/20230905/Download.svg" class="dimg"></a>
      </div>

      <div class="txt-21 m-t-24 color-07">
        {{ $t('message.news.blucap_has_developed') }}
      </div>
      <div class="m-t-24">
        <img :src="require(`../../assets/20230905/${this.$i18n.locale}/new02.png`)" class="nimg01">
      </div>
      <div class="flex row-between row-center m-t-12">
        <div class="txt-16">{{ $t('message.news.app_functionality') }}</div>
        <a :href="`zip/${this.$i18n.locale}/new02.png`" download>
          <img src="../../assets/20230905/Download.svg" class="dimg"></a>
      </div>

      <p class="txt-21 m-t-24 color-07">
        {{ $t('message.news.blucap_has_designed') }}
      </p>
      <p class="txt-21 m-t-12 color-07">{{ $t('message.news.basic_features') }}</p>

      <div class="m-t-24">
        <img :src="require(`../../assets/20230905/${this.$i18n.locale}/new03.png`)" class="nimg01">
      </div>
      <div class="flex row-between row-center m-t-12">
        <div class="txt-16">{{ $t('message.news.app_advanced') }}</div>
        <a :href="`zip/${this.$i18n.locale}/new03.png`" download>
          <img src="../../assets/20230905/Download.svg" class="dimg"></a>
      </div>

      <p class="txt-27 m-t-24">{{ $t('message.news.design') }}</p>
      <p class="txt-21 color-07">{{ $t('message.news.according_to_blucap') }}</p>

      <div class="m-t-24">
        <MPNewsSwipper />
      </div>
      <div class="m-t-24">
        <MPNewsSwipper1 />
      </div>

      <p class="txt-21 m-t-24 color-07">
        {{ $t('message.news.the_blucap_design') }}
      </p>
      <p class="txt-21 m-t-12 color-07"> {{ $t('message.news.when_unfolding') }}</p>

      <div class="m-t-24">
        <video playsInline loop autoPlay muted class="video_1">
          <source src="https://blk001.oss-us-west-1.aliyuncs.com/OUT3.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="flex row-between row-center m-t-12">
        <div class="txt-16">{{ $t('message.news.power_on') }}</div>
        <a href="zip/CG_AutoConnection_Video.mp4" download>
          <img src="../../assets/20230905/Download.svg" class="dimg"></a>
      </div>
      <p class="txt-21 m-t-24 color-07">
        {{ $t('message.news.the_charging') }}
      </p>
      <div class="m-t-24">
        <video playsInline loop autoPlay muted class="video_1">
          <source src="https://blk001.oss-us-west-1.aliyuncs.com/CG_QuickShift_Video.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="flex row-between row-center m-t-12">
        <div class="txt-16">{{ $t('message.news.release') }}</div>
        <a href="zip/CG_QuickShift_Video.mp4" download>
          <img src="../../assets/20230905/Download.svg" class="dimg"></a>
      </div>

      <div class="m-t-24">
        <MPNewsSwipper2 />
      </div>
      <p class="txt-27 m-t-24">{{ $t('message.news.customized') }}</p>
      <p class="txt-21 color-07">{{ $t('message.news.traditional_motorcycle') }}</p>

      <div class="m-t-24">
        <img src="../../assets/20230905/new06.png" class="nimg01">
      </div>
      <div class="flex row-between row-center m-t-12">
        <div class="txt-16">{{ $t('message.news.prescription_lenses') }}</div>
        <a href="zip/new06.png" download> <img src="../../assets/20230905/Download.svg" class="dimg"></a>
      </div>
      <p class="txt-27 m-t-24">{{ $t('message.news.cutting_edge') }}
      </p>
      <p class="txt-21 m-t-12 color-07">
        {{ $t('message.news.focused') }}
      </p>

      <div class="m-t-24">
        <img src="../../assets/20230905/new05.png" class="nimg01">
      </div>
      <div class="flex row-between row-center m-t-12">
        <div class="txt-16">{{ $t('message.news.microled') }}</div>
        <a href="zip/new05.png" download><img src="../../assets/20230905/Download.svg" class="dimg"></a>
      </div>
      <p class="txt-21 m-t-24 color-07">{{ $t('message.news.uniquely') }}</p>

      <div class="m-t-24">
        <MPNewsSwipper3 />
      </div>

      <div class="m-t-24">
        <MPNewsSwipper4 />
      </div>

      <p class="txt-27 m-t-24">{{ $t('message.news.sales_plan') }}
      </p>
      <p class="txt-21 color-07">
        {{ $t('message.news.official') }}
      </p>


      <p class="txt-27 m-t-24">{{ $t('message.news.market_resonance') }}
      </p>
      <p class="txt-21 color-07">{{ $t('message.news.fully') }}</p>

      <p class="txt-27 m-t-24">{{ $t('message.news.product_key') }}</p>
      <ul>
        <li>
          <p class="txt-21 color-07">{{ $t('message.news.real_time') }}</p>
        </li>
        <li>
          <p class="txt-21 color-07">{{ $t('message.news.other_hud') }}</p>
        </li>
        <li>
          <p class="txt-21 color-07">{{ $t('message.news.glasses_appearance') }}</p>
        </li>
        <li>
          <p class="txt-21 color-07">{{ $t('message.news.external_lens') }}</p>
        </li>
        <li>
          <p class="txt-21 color-07">
            {{ $t('message.news.replacement_lenses') }}
          </p>
        </li>
        <li>
          <p class="txt-21 color-07">{{ $t('message.news.inner_lens') }}</p>
        </li>
        <li>
          <p class="txt-21 color-07">
            {{ $t('message.news.voice_control') }}
          </p>
        </li>
        <li>
          <p class="txt-21 color-07">
            {{ $t('message.news.nose_pad') }}
          </p>
        </li>
        <li>
          <p class="txt-21 color-07">
            {{ $t('message.news.comes_with_a') }}
          </p>
        </li>
        <li>
          <p class="txt-21 color-07">
            {{ $t('message.news.light_engine') }}
          </p>
        </li>
        <li>
          <p class="txt-21 color-07">
            {{ $t('message.news.remote_control') }}
          </p>
        </li>
      </ul>




      <div class="border m-t-24">
        <div class="txt-21 w-10-ove">{{ $t('message.news.this_article') }}</div>
        <a class="m-t-12 flex row-between row-center" href="zip/txt.txt" download>
          <div class="w-10-btns flex row-start">
            <img src="../../assets/20230905/d1.svg" class="doimg">
            <div class="txt-16 w-10-btn">{{ $t('message.news.copy_text') }}</div>
          </div>
          <div></div>

        </a>
      </div>

      <div class="border m-t-12">
        <div class="txt-21 w-10-ove">{{ $t('message.news.download_all') }}</div>
        <a class="m-t-12 flex row-between row-center" :href="`zip/${this.$i18n.locale}/Photos.zip`" download>
          <div class="w-10-btns flex row-start">
            <img src="../../assets/20230905/d2.svg" class="doimg">
            <div class="txt-16 w-10-btn">{{ $t('message.news.zip569') }}</div>
          </div>
          <div></div>

        </a>
      </div>

      <div class="border m-t-12">
        <div class="txt-21 w-10-ove">{{ $t('message.news.advertisement') }}</div>
        <a class="m-t-12 flex row-between row-center" href="zip/Advertisement_Video_EN.mp4" download>
          <div class="w-10-btns flex row-start">
            <img src="../../assets/20230905/d2.svg" class="doimg">
            <div class="txt-16 w-10-btn">{{ $t('message.news.mp4106') }}</div>
          </div>
          <div></div>

        </a>
      </div>

      <div class="border m-t-12">
        <div class="txt-21 w-10-ove">{{ $t('message.news.vertical_advertisement') }}</div>
        <a class="m-t-12 flex row-between row-center" href="zip/30s_Vertical_Advertisement_Video_EN.mp4" download>
          <div class="w-10-btns flex row-start">
            <img src="../../assets/20230905/d2.svg" class="doimg">
            <div class="txt-16 w-10-btn">{{ $t('message.news.mp4378') }}</div>
          </div>
          <div></div>

        </a>
      </div>

      <div class="border m-t-12">
        <div class="txt-21 w-10-ove">{{ $t('message.news.cg') }}</div>
        <a class="m-t-12 flex row-between row-center" href="zip/CG_QuickShift_Video.mp4" download>
          <div class="w-10-btns flex row-start">
            <img src="../../assets/20230905/d2.svg" class="doimg">
            <div class="txt-16 w-10-btn">{{ $t('message.news.mp447') }}</div>
          </div>
          <div></div>

        </a>
      </div>

      <div class="border m-t-12">
        <div class="txt-21 w-10-ove">{{ $t('message.news.cg_auto') }}</div>
        <a class="m-t-12 flex row-between row-center" href="zip/CG_AutoConnection_Video.mp4" download>
          <div class="w-10-btns flex row-start">
            <img src="../../assets/20230905/d2.svg" class="doimg">
            <div class="txt-16 w-10-btn">{{ $t('message.news.mp445') }}</div>
          </div>
          <div></div>

        </a>
      </div>

      <div class="ling m-t-34"></div>

      <div class="txt-27 m-t-34">{{ $t('message.news.press_contacts') }}</div>
      <div class="txt-21 m-t-24">{{ $t('message.news.zhaot') }}</div>
      <div class="txt-16">{{ $t('message.news.brand_marketing') }}</div>
      <div class="txt-16 m-t-8 color-blue">{{ $t('message.news.ztt') }}</div>

      <div class="txt-21 m-t-24">{{ $t('message.news.b_m') }}</div>
      <div class="txt-16 m-t-8 color-blue">{{ $t('message.news.mb') }}</div>

    </div>
    <div class="bottom">
      <MPFooter />
    </div>
  </div>
</template>

<script>
import clipboard from 'clipboard';
import MPHeader1 from "@/components/header/MPHeader04.vue"; //头部
import MPFooter from "@/components/footer/MPFooter04.vue"; //底部
import MPNewsSwipper from "@/components/news/MPNewsSwipper.vue"; //底部
import MPNewsSwipper1 from "@/components/news/MPNewsSwipper1.vue"; //底部
import MPNewsSwipper2 from "@/components/news/MPNewsSwipper2.vue"; //底部
import MPNewsSwipper3 from "@/components/news/MPNewsSwipper3.vue"; //底部
import MPNewsSwipper4 from "@/components/news/MPNewsSwipper4.vue"; //底部
export default {
  components: {
    MPHeader1,
    MPFooter,
    MPNewsSwipper,
    MPNewsSwipper1,
    MPNewsSwipper2,
    MPNewsSwipper3,
    MPNewsSwipper4,
  },
  data() {
    return {
      changeClass1: 1,
      tabIndexs1: 3, //用来传值给子组件,来显示点击了第几个
      mindex: 100,
      
    };
  },
  mounted() {
    //监听页面滚动事件
    // 要防抖
    window.addEventListener("scroll", this.debounce(this.handleScroll, 10));
  },
  methods: {
    // 滑动
    handleScroll: function () {
      let top =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (top < 60) {
        this.changeClass1 = 1;
      } else {
        this.changeClass1 = 2;
      }
    },
    // 防抖函数
    debounce(fn, wait) {
      let timeout = null;
      return function () {
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(fn, wait);
      };
    },
    loc(values) {
      if (values == 1) {
        window.open('https://www.instagram.com/blucap_global/', '_blank')
      } else if (values == 2) {
        window.open('https://www.facebook.com/blucapsport/', '_blank')
      } else if (values == 3) {
        window.open('https://www.youtube.com/@Blucap', '_blank')
      } else if (values == 4) {
        window.open('https://twitter.com/Blucap_global', '_blank')
      }

    },
    copyClip() {
      let text = 'https://blucapsport.com/news';
      clipboard.copy(text);
      alert('已复制到剪贴板！');
    },
    handleMouseEnter(sindex) {
      this.mindex = sindex;
    },
    handleMouseLeave(sindex) {
      console.log('sindex', sindex);
      this.mindex = 100;
    }
  }
};
</script>


<style lang="less" scoped>
.w-2 {
  width: 100%;
  margin-top: 0.24rem;

  .w-2-video {
    width: 100%;
  }
}

.video_1 {
  width: 100%;
}

.bottom {
  border-top: 1px solid #303030;
  margin-top: 0.6rem;
}

.all {
  width: 100%;

  .content {
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    font-family: League Spartan;
    font-style: normal;
    color: #fff;

    .title01 {
      font-size: 0.42rem;
      font-weight: 500;
      line-height: normal;
      margin-top: 1rem;
    }

    .txt-16 {
      font-size: 0.16rem;
      font-weight: 400;
      line-height: 120%;
    }

    .w-10-ove {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .txt-21 {
      font-size: 0.21rem;
      font-weight: 400;
      line-height: 120%;
    }

    .txt-27 {
      font-size: 0.27rem;
      font-weight: 500;
      line-height: 120%;
    }

    .m-t-8 {
      margin-top: 0.08rem;
    }

    .m-t-12 {
      margin-top: 0.12rem
    }

    .m-t-24 {
      margin-top: 0.24rem;
    }

    .m-t-34 {
      margin-top: 0.34rem
    }

    .w-1 {
      width: 2.1rem;
      margin-top: 0.24rem;

      .simg {
        width: 0.27rem;
        opacity: 0.7;
        display: block;
      }

      .simg_active {
        opacity: 1;
      }
    }

    .nimg01 {
      width: 100%;
    }

    .dimg {
      width: 0.24rem;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 0.27rem;
  }

  ul li::marker {
    color: rgba(255, 255, 255, 0.70);
    font-size: 0.21rem;
  }

  .border {
    width: 100%;
    height: 1.2rem;
    background-color: #1B1B1B;
    border: 1px solid rgba(255, 255, 255, 0.20);
    padding-left: 0.24rem;
    padding-top: 0.24rem;
    padding-right: 0.24rem;

    .w-10-btns {
      border: 0.015rem solid rgba(255, 255, 255, 0.70);
      backdrop-filter: blur(0.15rem);
      padding: 0.08rem 0.12rem;
      // width: 0.9rem;
      color: #fff;
    }

    .w-10-btn {
      margin-left: 0.1rem;
    }

    .ww {
      // width: 1.32rem;
    }

    .ww-115 {
      width: 1.15rem;
    }

    .ww-156 {
      width: 1.56rem;
    }

    .ww-133 {
      width: 1.33rem;
    }

    .ww-131 {
      width: 1.31rem;
    }

    .ww-140 {
      width: 1.4rem;
    }

  }

  .ling {
    width: 100%;
    height: 0.01rem;
    background-color: #303030;
  }

  .color-blue {
    color: #249EFF;
  }

  .color-07 {
    color: rgba(255, 255, 255, 0.70);
  }
}</style>