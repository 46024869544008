<template>
    <div class="all newfour">
        <div class="swiper-container">
            <img v-lazy="require('../../assets/20230905/Blucap_Unique_Lenses.jpg')" alt class="img" />
            <!-- <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in swiperList" :key="index">
                    <img :src="item" alt class="img" />
                </div>
            </div> -->
            <div class="swiper-pagination"></div>
        </div>
        <div class="flex row-between row-center c-m-t">
            <div class="ctxt-4">{{ $t('message.news.b_m_unique') }}</div>
            <a href="zip/Blucap_Unique_Lenses.png" download><img src="../../assets/20230905/Download.svg" class="dimg"></a>
        </div>
    </div>
</template>

<script>
//import Swiper from "swiper";
// import "swiper/dist/css/swiper.css";


export default {
    data() {
        return {
            // 轮播图
            // swiperList: [
            //     require("../../assets/20230905/news/03.jpg"),
            //     require("../../assets/20230905/news/01.jpg"),
            //     require("../../assets/20230905/news/02.jpg"),
            // ],
        };
    },
    activated() {
        // this.$nextTick(() => {
        //     this.swiper();
        // });
    },

    methods: {
        // 轮播图
        // swiper() {
        //     new Swiper(".newfour .swiper-container", {
        //         pagination: { el: ".newfour .swiper-pagination" },
        //         observer: true,//修改swiper自己或子元素时，自动初始化swiper
        //         observeParents: true//修改swiper的父元素时，自动初始化swiper
        //         // loop: true,
        //         // autoplay: {
        //         //     disableOnInteraction: false,
        //         //     delay: 2000,
        //         // },
        //     });
        // },
    },
};
</script>
<style lang='less' scoped>
.all {
    width: 100%;

    .img {
        width: 100%;
    }

    .c-m-t {
        margin-top: 0.12rem;
    }

    .ctxt-4 {
        font-size: 0.16rem;
        font-weight: 400;
        line-height: 120%;
    }

    .dimg {
        width: 100%;
    }

}
</style>