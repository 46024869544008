<template>
    <div class="all newocfive">
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in swiperList" :key="index">
                    <img v-lazy="item" alt class="img" />
                </div>
            </div>
            <div class="swiper-pagination"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>
        </div>
        <div class="flex row-between row-center c-m-t">
            <div class="ctxt-4">{{ $t('message.news.b_m_factor') }}</div>
            <a href="zip/zip4.zip" download class="downImg"></a>
        </div>
    </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";


export default {
    data() {
        return {
            // 轮播图
            swiperList: [
                require("../../assets/20230905/news/01.jpg"),
                require("../../assets/20230905/news/02.jpg"),
            ],
        };
    },
    activated() {
        this.$nextTick(() => {
            this.swiper();
        });
    },

    methods: {
        // 轮播图
        swiper() {
            new Swiper(".newocfive .swiper-container", {
                pagination: { el: ".newocfive .swiper-pagination" },
                navigation: {
                    nextEl: '.newocfive .swiper-button-next',
                    prevEl: '.newocfive .swiper-button-prev',
                },
                //loop: true,
                // autoplay: {
                //     disableOnInteraction: false,
                //     delay: 2000,
                // },
            });
        },
    },
};
</script>
<style lang='less' scoped>
.downImg {
    width: 24px;
    height: 24px;
    background-image: url('../../assets/20230905/Download.svg');
    background-size: cover;
}

.all {
    width: 100%;

    .swiper-container {
        width: 100%;
        background-color: #303030;
        text-align: center;

        .img {
            width: 100%;
            height: 461px;
        }
    }


    .c-m-t {
        margin-top: 12px;
    }

    .ctxt-4 {
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
    }

    .dimg {
        width: 24px;
    }

}
</style>